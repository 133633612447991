import { ApiService as api } from '../Services/APIService';
import { TemplateService } from '../Services/TemplateService';

export class WidgetModule {
    static async Init() {
        console.log('===> Widget Module Init...');
        console.info(`===> Custom Widget Version: process.env.BUILD_VERSION`);
        let elements = document.querySelectorAll(
            '[data-component="CustomWidget"]',
        );

        elements.forEach((element) => {
            this.InitWidget(element);
        });
    }

    static async ReinitWidget(elementId) {
        let element = document.getElementById(elementId);
        WidgetModule.InitWidget(element);
    }

    static async ReinitAllWidgets() {
        this.Init();
    }

    static checkForUser() {
        // Get Data
        let userData = this.getUserData();

        // Check Data
        if (userData == 'null' || !userData || userData.length < 10) {
            return false;
        }

        let expireDt = this.getAuthExpiration();
        let now = new Date();

        if (expireDt < now) {
            return false;
        }

        return true;
    }

    static getUserData() {
        let userData = localStorage.getItem('mpp-widgets_AuthToken');
        return userData;
    }

    static getAuthExpiration() {
        let expireData = localStorage.getItem('mpp-widgets_ExpiresAfter');
        if (expireData) {
            console.log(expireData);
            let expireDt = Date.parse(expireData);
            console.log(expireDt);
            return expireDt;
        }
        return null;
    }

    static async InitWidget(element) {
        let storedprocedure = element.getAttribute('data-sp');
        let template = element.getAttribute('data-template');
        let templateId = element.getAttribute('data-templateId');
        let cache = element.getAttribute('data-cache');
        let params = element.getAttribute('data-params');

        let requireUser = false;
        if (
            element.getAttribute('data-requireUser')
            && element.getAttribute('data-requireUser').toLowerCase() === 'true'
        ) {
            requireUser = true;
        }

        let debugMode = false;
        if (element.getAttribute('data-debug')) {
            debugMode = true;
        }

        let useCalendar = false;
        if (element.getAttribute('data-useCalendar')) {
            useCalendar = true;
        }

        let authOverride = false;
        if (
            element.getAttribute('data-authOverride')
            && element.getAttribute('data-authOverride').toLowerCase()
                === 'true'
        ) {
            authOverride = true;
        }

        if (!useCalendar) {
            if (params && params.indexOf('@') < 0) {
                console.warn(
                    "params must include the '@' character to correctly pass parameters to the data stored procedure",
                );
            }

            // TODO: Split on & symbol and check each for @
        }

        if (storedprocedure && useCalendar) {
            console.warn(
                'Stored Procedure and Use Calendar are configured.  In this case, only UseCalendar will be processed.  Please reivew your custom widget configuration and correct to avoid this warning.',
            );
        }

        console.info('**************************************************');
        console.info(`Element ID:       ${element.id}`);
        console.info(`Stored Procedure: ${storedprocedure}`);
        console.info(`Params:           ${params}`);
        console.info(`Template:         ${template}`);
        console.info(`Template ID:      ${templateId}`);
        console.info(`Require User:     ${requireUser}`);
        console.info(`Cache Data:       ${cache}`);
        console.info(`Use Calendar:     ${useCalendar}`);
        console.info(`Params:           ${params}`);
        console.info(`Debug Enabled:    ${debugMode}`);
        console.info(`Auth Override:    ${authOverride}`);
        console.info('**************************************************');

        this.LoadWidget(
            element.id,
            storedprocedure,
            params,
            template,
            templateId,
            requireUser,
            cache,
            useCalendar,
            debugMode,
            authOverride,
        );
    }

    static async setupAuthRecheck() {
        if (this.authCheckCount == null) {
            this.authCheckCount = 0;
        }

        if (this.authCheckTimer == null && this.authCheckCount < 4) {
            console.info('|||===> Auth Check Scheduled');
            let wid = this;

            wid.authCheckTimer = window.setTimeout(function () {
                wid.authCheckTimer = null;
                wid.recheckAuth();
            }, 500);
            wid.authCheckCount++;
        }
    }

    static async recheckAuth() {
        console.info('|||===> Checking Auth');
        if (this.checkForUser()) {
            this.ReinitAllWidgets();
        } else {
            this.setupAuthRecheck();
        }
    }

    static async LoadWidget(
        elementId,
        storedprocedure,
        params,
        template,
        templateId,
        requireUser,
        cache,
        useCalendar,
        debugMode,
        authOverride,
    ) {
        let element = document.getElementById(elementId);

        let data = {};

        // Execute Client Side Auth Check
        // NOTE: Widgets doesn't remove the localStorage, but sets it to "null" on logout
        if (requireUser) {
            if (!this.checkForUser()) {
                data.userAuthenticated = false;
                console.info('|||===> No user is logged in.');

                if (!authOverride) {
                    element.innerHTML = `<div class="alert alert-danger alert-nologin">You must be logged in to see the details of this widget.</div>`;
                } else {
                    if (debugMode) {
                        console.log('|||===> Data');
                        console.log(data);
                    }

                    // Attempt to render Widget Template with only userAuthenticated data elements
                    await this.RenderTemplateWithData(
                        templateId,
                        template,
                        data,
                        element,
                    );
                }

                this.setupAuthRecheck();
                return;
            }
        }

        if (useCalendar) {
            data = await this.LoadCalendarData(params);
        } else {
            data = await this.LoadWidgetData(
                this.getUserData(),
                requireUser,
                storedprocedure,
                params,
                cache,
            );
        }

        // Append Widget ID data
        // Allows for more complicated re-use of templates in a single DOM
        data.widgetId = elementId;

        // Boolean for debugMode
        if (debugMode) {
            console.log('|||===> Data');
            console.log(data);
        }

        // Append Authenticated Status to Widget Data
        console.info('***====> Checking for Authentication <====***');
        if (this.checkForUser()) {
            console.info('===> User Authenticated');
            data.userAuthenticated = true;
        } else {
            console.warn('===> User NOT Authenticated');
            data.userAuthenticated = false;
        }

        // Attempt to render Widget Template
        await this.RenderTemplateWithData(templateId, template, data, element);

        // Trigger the widgetLoadedEvent
        // Pass the data object as data parameter
        let widgetLoadedEvent = new CustomEvent('widgetLoaded', {
            detail: {
                widgetId: `${element.id}`,
                data: data,
            },
        });

        // Custom Event Dispatch for Element
        element.dispatchEvent(widgetLoadedEvent);

        // Custom Event Dispatch for Window
        window.dispatchEvent(widgetLoadedEvent);
    }

    static async RenderTemplateWithData(templateId, template, data, element) {
        try {
            if (templateId) {
                let templateElement = document.getElementById(templateId);
                let mergedTemplate =
                    await TemplateService.GetRenderedTemplateString(
                        templateElement.innerText,
                        data,
                    );
                element.innerHTML = mergedTemplate;
            } else if (template) {
                let mergedTemplate = await TemplateService.GetRenderedTemplate(
                    template,
                    data,
                );
                element.innerHTML = mergedTemplate;
            } else {
                console.error('Error while rendering template.');
                console.error(err);

                element.innerHTML = `<div class="alert alert-danger error">An error occurred while rendering the widget template</div>`;
            }
        } catch (err) {
            console.error('Error while rendering template.');
            console.error(err);

            element.innerHTML = `<div class="alert alert-danger error">An error occurred while rendering the widget template</div>`;
        }
    }

    static async LoadWidgetData(
        userData,
        requireUser,
        storedprocedure,
        params,
        cache,
    ) {
        let url = `https://ministryplatform.perimeter.org/CustomWidgetsAPI/api/CustomWidget?storedProcedure=${storedprocedure}`;

        if (params) {
            url += `&spParams=${encodeURIComponent(this.replaceParameters(params))}`;
        }

        // Add userData to Request Parameters
        if (userData) {
            url += `&userData=${userData}`;
        }

        // Add cacheData to Request Parameters
        if (cache) {
            url += `&cacheData=${cache}`;
        }

        if (requireUser) {
            // Add requireUser parameter to Request
            url += `&requireUser=true`;

            // Check UserData
            if (!userData) {
                console.error('Not logged in...');
                // Show Login Required
                element.innerHTML = `<div class="alert alert-danger error">Please login to see this widget.</div>`;
                return;
            }
        }

        return await api.getData(url);
    }

    static async LoadCalendarData(params) {
        let url = `https://ministryplatform.perimeter.org/CustomWidgetsAPI/api/Events/GetEvents`;

        if (params.indexOf('?') > -1) {
            let resultString = params.replace('?', '');
            params = resultString;
        }

        if (params) {
            url += `?${params}`;
        }

        // Ensure that JSON DataFormat is enabled
        // Remove any ICAL Reference
        if (params.indexOf('&DataFormat=json') < -1) {
            url += `&DataFormat=json`;
            url.replace('&DataFormat=ical', '');
        }

        let data = await api.getData(url);

        let modelData = { Events: data };

        return modelData;
    }

    static replaceParameters(params) {
        let tParams = params;

        let re = /\[.*?]/gi;
        let match;
        while ((match = re.exec(params)) != null) {
            tParams = tParams.replace(
                match[0],
                this.getParameterByName(match[0]),
            );
        }

        return tParams;
    }

    static getParameterByName(qsParam, url = window.location.href) {
        let name = qsParam.replace('[', '');
        name = name.replace(']', '');

        console.log('|||===> Getting Querystring Parameter:' + name);

        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';

        console.log(
            '|||===> Param Value:'
                + decodeURIComponent(results[2].replace(/\+/g, ' ')),
        );

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}
